//@ts-check

import { Resources } from "./resources.js";
import { Simulation } from "./simulation.js";
import { InteractiveCanvas } from "./audio_visual/interactive_canvas.js";
import { WebBrowserClient } from "./webBrowserClient.js";
import { AuthorInterface } from "./sceneAuthorInterface/authorInterface.js";
import { WebAudio } from "./audio_visual/audio/webAudio.js";
import { NullWebAudio } from './audio_visual/audio/NullWebAudio.js';
import { ExternalModules } from './modules/ExternalModules.js';
import { InternalModules } from './modules/internalModules.js';
import { SceneModules } from './modules/sceneModules.js';
import { InternalAuth } from "./auth/InternalAuth.js";
import { WebApplication } from './webApplication.js';
import { VisualElements } from './audio_visual/visual/VisualElements.js';
import { WebApplicationServer } from './webApplicationServer.js';
import { WebApplicationState } from './WebApplicationState.js';
import { MeidaSourcePlayerComponent } from './audio_visual/visual/MediaSourcePlayerComponent.js';
import { StaticRouter } from "./routing/StaticRouter.js";

/**
 * @type {string}
 */
let versionNumber = "0.4.1";

/**
 * @callback newWebApplicationFunction
 * @param {WebApplicationState} withState 
 * @returns {WebApplication}
*/
/**
 * @callback newServerFunction
 * @param {WebApplicationState} withState 
 * @returns {WebApplicationServer}
*/

/**
 * 
 * @param {WebApplicationState} state 
 * @param {newWebApplicationFunction} newWebApplication 
 * @param {newServerFunction} newServer 
 * @param {boolean} hasExternalModules 
 * @param {boolean} hasAuth 

 */
export async function startClient(state, newWebApplication, newServer, hasExternalModules = true, hasAuth = true) {
	state.libraryVersionNumber = versionNumber;

	state.resources = new Resources();
	await state.resources.Initialize();

	state.application = newWebApplication(state);
	state.application.initialize();
	
	state.server = newServer(state);
	await state.server.initialize();

	state.browser = new WebBrowserClient(state.resources, state.application, state.server);
	state.browser.initialize();
	state.server.setBrowser(state.browser);

	state.application.setServer(state.server);

	state.account = await state.server.getCustomerAccount();
	state.account.initialize();
	state.server.setClientCustomerAccount(state.account);

	state.canvas = new InteractiveCanvas(state);
	state.canvas.initialize();
	state.application.platform_canvas = state.canvas.platformCanvas;

	if (state.application.settings.getSetting(WebApplication.IsMSVideoEnabledSettingName)) {
		state.msePlayer = new MeidaSourcePlayerComponent(state);
		await state.msePlayer.initialize();
		state.canvas.addComponent(state.msePlayer);
	}

	state.author = new AuthorInterface(state.resources, state.canvas, state.account);
	state.author.initialize();

	var hasAudio = state.application.getSetting(WebApplication.IsAudioEnabledSettingName)
	state.audio = hasAudio ? new WebAudio(state.resources, state.account) : new NullWebAudio();
	state.audio.initialize();
	state.canvas.addComponent(state.audio);

	VisualElements.initializeResourceTypes(state.application);

	state.simulation = new Simulation(state.resources, state.canvas, state.account, state.server, state.audio, state);
	await state.simulation.initialize();
	state.canvas.addComponent(state.simulation);

	if (hasExternalModules) {
		state.externalModules = new ExternalModules(state, state.resources, state.canvas, state.account, state.server, state.audio);
		state.application.initializeExternalModules(state);
		state.externalModules.initialize();
		state.internalModules = new InternalModules(state.externalModules);
		state.application.initializeInternalModules(state);
		state.internalModules.initialize();
	}
	state.sceneModules = new SceneModules();
	state.application.initializeSceneModules(state);
	state.sceneModules.initialize();


	state.author.components.push(state.browser);
	state.author.components.push(state.application);
	state.author.components.push(state.audio);
	state.author.components.push(state.account);
	//state.author.components.push(state.simulation);
	state.author.components.push(state.server);

	if (hasExternalModules) {
		state.author.components.push(state.externalModules);
		//state.author.components.push(state.internalModules);
		state.externalModules.authoringInterface = state.author;
		state.internalModules.authoringInterface = state.author;
	}


	//state.author.components.push(state.sceneModules);
	state.sceneModules.authoringInterface = state.author;

	state.canvas.addComponent(state.author);
	state.author.diagnostics_overlay.set_profile(state.canvas.platformCanvas.name);
	state.author.diagnostics_overlay.set_brand(state.canvas.platformCanvas.brand);

	hasAuth = hasAuth && state.application.getSetting(WebApplication.IsAuthEnabledSettingName)
	state.auth = new InternalAuth(state);
	await state.auth.initialize();
	
	state.router = new StaticRouter(state);
	state.router.initialize();

	document.addEventListener("visibilitychange", () => {
		if (document.visibilityState == "hidden") {
			state.saveState();
		}
	});

    await state.canvas.start();
    return;
}