//@ts-check
import { ExternalModule } from './../externalModules/ExternalModule';
import { GameCenterModuleIntegration } from './../externalModules/GameCenterModuleIntegration';
/**
 * 
 */
export class ChessExternalModule extends ExternalModule {
	/**
	 * 
	 */
	constructor() {
		super();
		this.name = "chess";
		this.html = "https://chess.nmp.nonprod-sinclairstoryline.com";
		// this.html = "http://localhost:3000";
		this.addIntegrations = (openModule) => openModule.integrations[0] = new GameCenterModuleIntegration(openModule) //.push(new GameCenterModuleIntegration(openModule));
	}
}

