//@ts-check
import { Treeview } from "../view/treeview";
import { OpenModule } from "../externalModules/OpenModule";
import { ModuleIntegration } from "../externalModules/ModuleIntegration";
import { v4 as uuidv4 } from "uuid";
import * as htmlHelper from "../htmlHelper";

import { BaseModules, ModulesCommon } from "./ModulesCommon";
import { InteractiveCanvas } from '../audio_visual/interactive_canvas';
import { AuthorInterface } from '../sceneAuthorInterface/authorInterface';
import { Resources } from '../resources';
import { CustomerAccount } from '../customerAccount';
import { WebApplicationServer } from '../webApplicationServer';
import { WebAudio } from '../audio_visual/audio/webAudio';
import { WebApplicationState } from '../WebApplicationState';
import { WebApplication } from '../webApplication';
import { ExternalModule } from '../externalModules/ExternalModule';
import page from "page";

/**	
* @type {object}
*/
export var dialogOptions = {};
/**
* @type {string|undefined}
*/
export var id;
/**
 * 
 */
export class ExternalModules extends BaseModules {
	/**
	* @type {string}
	*/
	static fullscreenIframeDivId = ModulesCommon.fullscreenIframeDivId;
	/**
	* @type {InteractiveCanvas}
	*/
	icanvas;
	/**
	* @type {AuthorInterface|undefined}
	*/
	authoringInterface;
	/**
	* @type {Resources}
	*/
	resources;
	/**
	* @type {CustomerAccount}
	*/
	account;
	/**
	* @type {WebApplicationServer}
	*/
	server;
	/**
	* @type {Array.<ExternalModule>}
	*/
	static modules = [];
	/**
	* @type {object}
	*/
	json;
	/**
	* @type {WebAudio}
	*/
	audio;
	/**
   * @type {Array.<OpenModule>}
   */
	open_modules = [];
	/**
	* @type {WebApplicationState}
	*/
	state;
	/**
	 * 
	 * @param {WebApplicationState} state 
	 * @param {Resources} resources 
	 * @param {InteractiveCanvas} icanvas 
	 * @param {CustomerAccount} account 
	 * @param {WebApplicationServer} server 
	 * @param {WebAudio} audio 
	 * @param {object} json 
	 */
	constructor(state, resources, icanvas, account, server, audio, json = {}) {
		super();
		this.resources = resources;
		this.icanvas = icanvas;
		this.icanvas.externalModules = this;
		this.account = account;
		this.server = server;
		this.json = json;
		this.audio = audio;
		this.ready = false;
		this.state = state;
		this.auth = null;
	}
	/**
	 * @returns {WebApplication}
	 */
	get application() {
		return this.account.application;
	}
	/**
	 * 
	 * @returns {string}
	 */
	stroageItemName() {
		return this.resources.combineJsonResourceName(this.application.name, this.account.name, "externalModules");
	}
	/**
	 * 
	 */
	initialize() {
		if (!this.json.modules) {
			this.json.modules = ExternalModules.modules;
		}
		window.addEventListener("message", (event) => {
			this.postMessageHandler(event);
		}, false);
	}

	/**
	 * 
	 * @param {MessageEvent<any>} event 
	 */
	postMessageHandler(event) {
		this.open_modules.forEach((element) => {
			element.windowMessage(event);
		});
	}
	/**
	 * 
	 */
	saveState() {
		// this.resources.setLocalStorageItemAsJson(this.stroageItemName(), this.json);
	}
	/**
	 * 
	 */
	shutdown() {
		this.saveState();
	}

	/**
	 * 
	 * @returns {string}
	 */
	getAuthorInterfaceName() {
		return "External Modules";
	}
	/**
	 * 
	 * @returns {HTMLElement}
	 */
	createAuthorInterfaceElement() {
		let layout = new Treeview();
		layout.buildElementFromJson(this.json, 1);
		return layout.element;
	}

	/**
	 * 
	 * @param {string} uuid 
	 * @param {string} name 
	 * @returns {string}
	 */
	static newDialogiFrame(uuid, name = "module") {
		var fullscreen_iframe_div = document.getElementById(ExternalModules.fullscreenIframeDivId);

		var div_id = `module-instance-${uuid}`;
		// title="${name}"
		var div = htmlHelper.htmlToElement(`<div id=${div_id} ></div>`);

		if (fullscreen_iframe_div && fullscreen_iframe_div.parentElement && div) {
			fullscreen_iframe_div.parentElement.insertBefore(div, fullscreen_iframe_div.nextSibling);
		}

		return div_id;
	}
	/**
	 * 
	 * @param {string} name 
	 * @returns {ExternalModule|undefined}
	 */
	findModule(name) {
		for (let index = 0; index < this.json.modules.length; index++) {
			const element = this.json.modules[index];
			if (element.name == name) {
				return element;
			}
		}
	}
	/**
	 * 
	 * @param {string} name 
	 * @returns {OpenModule|undefined}
	 */
	findOpenModule(name) {
		for (let index = 0; index < this.open_modules.length; index++) {
			const element = this.open_modules[index];
			if (element.name == name) {
				return element;
			}
		}
	}
	/**
	 * 
	 * @param {string} name 
	 * @param {boolean} isFullscreen 
	 * @returns 
	 */
	openModule(name, isFullscreen = true) {
		page.redirect("/loading");

		this.state.auth.isAuthenticated((isAuthed) => {
			if (!isAuthed) {
				this.state.router.redirectToLogin();
				localStorage.setItem("requestedPage", name);
				return;
			} else {
				// Allow user to continue, remove login page 
				this.state.router.resetPageToCanvas();
				page.redirect("/");
				localStorage.removeItem("requestedPage");
			}
			const module = this.findModule(name);	
			if (this.open_modules[0]) { 
				this.closeModule(this.open_modules[0]) 
			}
			this.ready = false;
			if (!module) return undefined; 
			var uuid = uuidv4();

			if (isFullscreen) {
				id = ExternalModules.fullscreenIframeDivId;
				this.icanvas.deactivate();
				this.authoringInterface?.deactivate();
				this.audio.deactivate();
			} else {
				id = ExternalModules.newDialogiFrame(uuid, name);
			}

			const iframeDiv = /** @type {HTMLElement} */ (document.getElementById(id));

			iframeDiv.style.display = 'none'
			iframeDiv.style.zIndex = '100';
			iframeDiv.innerHTML = '';

			const iframe = /** @type {HTMLIFrameElement} */ document.createElement("iframe");

			let userId;

			const openModule = new OpenModule(module, id, iframe, uuid, userId, this.state);

			if (isFullscreen) {
				iframeDiv.style.display = "block";
			}

			iframeDiv.innerHTML = "";
			openModule.externalExtensions = this;
			iframe.allow = "clipboard-write *"; // allow iframes to access clipboard

			if (isFullscreen) {
				iframe.style.height = "100vh";
				iframe.style.width = "100vw";
			} else {
				iframe.style.height = "100%";
				iframe.style.width = "100%";
			}

			iframe.onload = () => {
				iframe.contentWindow?.focus()
				openModule.start();
			};

			openModule.iframe.src = module.html;
			iframeDiv.appendChild(iframe);
			this.open_modules[0] = openModule;
			return openModule;
		});
	}
	
	/**
	 * 
	 */
	closeModules() {
		var i = this.open_modules.length;
		while (i--) {
			this.closeModule(this.open_modules[i]);
		}
	
	}
	/**
	 * 
	 * @param {string} name 
	 */
	closeModuleByName(name) {
		var found = this.findOpenModule(name);
		if (found) {
			this.closeModule(found);
		}
	}
	/**
	 * 
	 * @param {string} name 
	 */
	closeModuleByDivId(name) {
		var found = this.findOpenModule(name);
		if (found) {
			this.closeModule(found);
		}
	}
	/**
	 * 
	 * @param {string} id 
	 * @returns {OpenModule|undefined}
	 */
	findOpenModuleByDivId(id) {
		for (let index = 0; index < this.open_modules.length; index++) {
			const element = this.open_modules[index];
			if (element.iframeDivId == id) {
				return element;
			}
		}
	}
	/**
	 * 
	 * @param {OpenModule} module 
	 */
	closeModule(module) {
		if (module.isClosed) {
			return;
		}
		module.isClosed = true;
		this.state.router.resetPageToCanvas();

		var iframeDiv =/** @type {HTMLElement} */ ( document.getElementById(module.iframeDivId));
		iframeDiv.style.display = "none";
		iframeDiv.innerHTML = "";

		if (module.iframeDivId == ExternalModules.fullscreenIframeDivId) {
			this.icanvas.reactivate();
			this.authoringInterface?.reactivate();
			this.audio.reactivate();
			this.icanvas.try_invalidated_draw();
		}
		this.open_modules = this.open_modules.filter((each) => !(each === module));
	}
}