export function successPage() 
{
    const viewport = document.getElementById("viewport");
    const loginContainer = document.createElement("div");

    viewport.innerHTML = "";
    document.getElementById("debug-table")?.remove();
    viewport.appendChild(loginContainer);
    loginContainer.innerHTML = `
        <div class="login-page" id="success">
            <h2>Success!</h2>
            <p>You may leave this page.</p>
        </div>
    `;
}
