// Store the previous email and username to prevent 

import { hideLoadingIndicator, showLoadingIndicator } from "./login";

// unnecessary API calls if the user has not changed the input.
let previousEmail = "";
let previousUsername = "";

export function signUpPage(state, connectionID) 
{
    state.router.addPageToViewport(`
    <div class="login-page">
        <form id="login-form" class="auth-form">
            <h1>Create a Free Account</h1>

            <div class="input-container">
                <div class="input-row">
                    <label for="email" id="email-label">Email</label>
                    <div id="email-error" class="error"></div>
                </div>
                <input type="text" id="email" placeholder="Email" />
            </div>

            <div class="input-container">
                <div class="input-row">
                    <label for="username">Username (visible to others)</label>
                    <div id="username-error" class="error"></div>
                </div>
                <input type="text" id="username" placeholder="Username" />
            </div>

            <div class="input-container">
                <label for="password">Password</label>
                <input type="password" id="password" placeholder="Password" />
            </div>

            <button type="submit">Create account</button>
            <div id="loading-indicator" class="loading-indicator" style="display: none;"></div>
        </form>
        <a class="new-user-link" href="/login#${connectionID}">Already have an account? Sign in</a>
    </div>
    `);
    addCreateAccountHandler(connectionID);
    addInputValidation();
}

/**
 * Adds the input validation for the email and username fields.
 * These fields are validated on input and on focusout.
 * Validation calls our backend to check if the email or username is already in use.
 */
function addInputValidation() 
{
    const emailInput = document.getElementById("email");
    const usernameInput = document.getElementById("username");

    emailInput.addEventListener("input", validateEmail);
    emailInput.addEventListener("focusout", checkDuplicateEmail);
    usernameInput.addEventListener("focusout", checkDuplicateUsername);
}

/**
 * Checks if the email is valid and if it is not already in use.
 * @returns {boolean} true if the email is valid, false otherwise.
 */
function checkDuplicateEmail() 
{
    const email = document.getElementById("email").value;
    if (!validateEmail())
    {
        return;
    }

    previousEmail = email;
    const emailError = document.getElementById("email-error");
    showValidationMessage(emailError, "Checking email availability...", "green");

    fetch("https://lakeside-auth-api.nmp.nonprod-sinclairstoryline.com/auth/nonprod", 
    {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Origin": window.location.origin
        },
        body: JSON.stringify({
            "action": "check_available",
            "identifier": email
        }),
    }).then(async (response) => 
    {
        if (response.ok) 
        {
            const data = await response.json();
            if (data.ok === "taken") 
            {
                showValidationMessage(emailError, "Email already in use.", "red");
            } 
            else if (data.ok === "available") 
            {
                showValidationMessage(emailError, "Good to go!", "green");
            }
        }
    });
}

/**
 * Shows a validation message on the element with the given message
 * @param {Element} element the element to show the message on.
 * @param {String} message the message to show.
 * @param {String} color the color of the message.
 */
function showValidationMessage(element, message, color)
{
    element.style.color = color;
    element.textContent = message;
    element.style.display = "block";
}

/**
 * Validates the email input field.
 * @returns {boolean} true if the email is valid, false otherwise.
 */
function validateEmail() 
{
    const email = document.getElementById("email").value;
    const emailError = document.getElementById("email-error");
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email) || previousEmail === email) 
    {
        showValidationMessage(emailError, "Invalid email address", "red");
        return false;
    } 
    else 
    {
        emailError.textContent = "";
        emailError.style.display = "none";
        return true;
    }
}


function isValidUsername(username)
{
    return (username.length > 0 && previousUsername !== username && username.length <= 20);
}

/**
 * Checks if the username is valid and if it is not already in use.
 * @returns {boolean} true if the username is valid, false otherwise.
 */
function checkDuplicateUsername() 
{
    const username = document.getElementById("username").value;
    const usernameError = document.getElementById("username-error");
    
    if (!isValidUsername(username)) 
    {
        showValidationMessage(
            usernameError, 
            "Username cannot be empty or exceed 20 characters.", 
            "red"
        );
        return;
    }

    previousUsername = username;
    showValidationMessage(usernameError, "Checking username availability...", "green");

    fetch("https://lakeside-auth-api.nmp.nonprod-sinclairstoryline.com/auth/nonprod", 
    {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Origin": window.location.origin
        },
        body: JSON.stringify({
            "action": "check_available",
            "identifier": username
        }),
    }).then(async (response) => 
    {
        if (response.ok) 
        {
            const data = await response.json();
            if (data.ok === "taken") 
            {
                showValidationMessage(usernameError, "Username already in use.", "red");
            } 
            else if (data.ok === "available") 
            {
                showValidationMessage(usernameError, "Good to go!", "green");
            }
        }
    });
}

/**
 * Adds the login handler to the login form.
 * Passwords are sent to the server securely over https, as well as the connectionID
 * to notify the server that the user has authenticated.
 */
function addCreateAccountHandler(connectionID) 
{
    const loginForm = document.getElementById("login-form");

    loginForm.addEventListener("submit", (event) => 
    {
        event.preventDefault();
        showLoadingIndicator();
        const email = document.getElementById("email").value;
        const password = document.getElementById("password").value;
        const username = document.getElementById("username").value;

        if (!validateEmail())
        {
            hideLoadingIndicator();
            return;
        }

        fetch("https://lakeside-auth-api.nmp.nonprod-sinclairstoryline.com/auth/nonprod", 
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json", 
                "Origin": window.location.origin
            },
            body: JSON.stringify({
                "action": "sign_in",
                "email": email, 
                "password": password, 
                "username": username,
                "new_user": true
            }),
        }).then((response) => 
        {
            hideLoadingIndicator();
            if (response.ok) 
            {
                window.location.href = `/login#${connectionID}`;
            } 
            else 
            {
                // TODO we will add some CSS indicator here
                hideLoadingIndicator();
                alert("Failed to create account. Please try again.");
            }
        });
    });
}
