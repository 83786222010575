import { WebApplicationState } from "../../../../WebApplicationState";

/**
 * Shows the phone authentication page, 
 * shown to the phone when they have scanned the QR code.
 * @param {String} connectionID the connection ID to show the QR code for.
 * @param {WebApplicationState} state the state of the application.
 */
export function showPhoneLoginPage(connectionID, state) {
    state.router.addPageToViewport(`
        <div class="login-page">
        <form id="login-form" class="auth-form">
            <h1>Sign in</h1>
            
            <div class="input-container">
                <label for="email" id="email-label">Email</label>
                <input type="text" id="email" placeholder="Email" />
            </div>

            <div class="input-container">
                <label for="password">Password</label>
                <input type="password" id="password" placeholder="Password" required />
            </div>
            
            <button type="submit">Sign in</button>
            <div id="loading-indicator" class="loading-indicator" style="display: none;"></div>
            <a class="new-user-link" href="/login#new/${connectionID}">Create an account</a>
        </form>
        </div>
    `);
    addLoginHandler(connectionID, state);
}

/**
 * Validates the email input for the login form.
 * @param {String} email the email to validate.
 * @returns true if the email is valid, false otherwise.
 */
function validateInput(email) 
{
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) 
    {
        invalidCredentialAnimation();
        return false;
    }
    return true;
}

/**
 * Adds an animation to the login form when the credentials are invalid.
 * This is used for only the email field for now.
 */
export function invalidCredentialAnimation() 
{
    hideLoadingIndicator();
    const password = document.getElementById("password");
    const email = document.getElementById("email");

    // Add and remove the incorrect-value class for animating the incorrect value.
    password.classList.add("incorrect-value");
    email.classList.add("incorrect-value");
    setTimeout(() => 
    {
        password.classList.remove("incorrect-value");
        email.classList.remove("incorrect-value");
    }, 1000);
}

/**
 * Shows the loading indicator when the user is authenticating.
 */
export function showLoadingIndicator()
{
    document.getElementById("loading-indicator").style.display = "block";
    document.getElementById("login-form").getElementsByTagName("button")[0].style.display = "none";
}

/**
 * Hides the loading indicator when the user is done authenticating.
 */
export function hideLoadingIndicator()
{
    document.getElementById("loading-indicator").style.display = "none";
    document.getElementById("login-form").getElementsByTagName("button")[0].style.display = "block";
}

/**
 * Adds the login handler to the login form.
 * Passwords are sent to the server securely over https, as well as the connectionID
 * to notify the server that the user has authenticated.
 * @param {String} connectionID the connection ID to send to the server.
 * @param {WebApplicationState} state the state of the application.
 */
function addLoginHandler(connectionID, state) 
{
    const loginForm = document.getElementById("login-form");
    loginForm.addEventListener("submit", (event) => 
    {
        event.preventDefault();
        showLoadingIndicator();

        const email = document.getElementById("email").value;
        const password = document.getElementById("password").value;

        if (!validateInput(email)) 
        {
            return;
        }

        try 
        {
            fetch("https://lakeside-auth-api.nmp.nonprod-sinclairstoryline.com/auth/nonprod", 
            {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                credentials: "include",
                body: JSON.stringify({ 
                    "action": "sign_in", 
                    "email": email, 
                    "password": password, 
                    "connectionID": connectionID,
                    "new_user": false
                }),
            }).then((response) => 
            {
                hideLoadingIndicator();
                if (response.ok) 
                {
                    window.location.href = "/login#success";
                }
                else 
                {
                    invalidCredentialAnimation();
                }
            });
        } 
        catch (err) 
        {
            // TODO add error handling on frontend.
            alert("Error authenticating, please try again.");
            console.log("Error while authenticating: ", err);
            hideLoadingIndicator();
        }
    });
}
