import { WebApplicationState } from "../WebApplicationState";
import page from "page";
import { showPendingPage } from "./routes/login/pending/pendingPage";
import { showPhoneLoginPage } from "./routes/login/phoneAuth/login";
import { showTVLoginPage } from "./routes/login/TVLogin";
import { signUpPage } from "./routes/login/phoneAuth/newAccount";
import { successPage } from "./routes/login/phoneAuth/success";
import { ScenePath } from "../sceneGraph/ScenePath";
import { SceneChangeRequest } from "../sceneGraph/SceneChangeRequest";
import { showLoadingPage } from "./routes/loading/loading";

/**
 * @typedef {Object} RouterInterface
 * @property {() => void} redirectToLogin
 * @property {() => void} initialize
 * @property {() => void} saveState
 * @property {() => void} shutdown
 * @property {() => void} loadPageContent
 */

/**
 * @implements {AuthInterface}
 */
export class StaticRouter {

    /**
     * @param {WebApplicationState} state 
     */
    constructor(state) {
        this.state = state;
        this.requestedPage = null;
    }

    /**
     * Initialize the router by defining all routes.
     */
    initialize() {
        page("/login", async () => {

            /*
            /login#pending/connectionID 
            Shows the pending page when the TV is waiting for the phone to authenticate
            */
            if (window.location.hash.includes("pending")) {
                const connectionID = window.location.hash.split("/")[1];
                if (connectionID) {
                    showPendingPage(connectionID, this.state);
                    return;
                }
            
            } else if (window.location.hash.includes("success")) {
                successPage();
                return;

            /* 
            /login#new
            Shows the create account page
            */
            } else if (window.location.hash.includes("new")) {
                const connectionID = window.location.hash.split("/")[1];
                signUpPage(this.state, connectionID);
                return;

            /* 
            /login#connectionID 
            Shown to the phone or device when they have scanned the QR code
            */
            } else if (!window.location.hash.includes("redirect")) {
                const connectionID = window.location.hash.split("#")[1];
                if (connectionID) {
                    showPhoneLoginPage(connectionID, this.state);
                    return;
                }
            }
            showTVLoginPage(this.state);
        });
        page("/loading", () => {
            showLoadingPage(this.state);
        });
        page("/", () => {});
        page(); // Initialize all routes
    }

    /**
     * Redirects to the login page.
     */
    redirectToLogin(requestedPage) {
        this.requestedPage = requestedPage;
        page.redirect("/login");
    }

    resetPageToCanvas() {
        this.state.canvas.viewport.innerHTML = `
            <canvas id="viewport_canvas">This text is displayed if your browser does not support HTML5 Canvas.</canvas>
            <video id="video-" style="display: none; "></video>
            <video id="video-a" style="display: none; "></video>
            <video id="video-b" style="display: none; "></video>
        `;
        this.state.canvas.enableInput();
        this.state.canvas.initialize();
    }

    addPageToViewport(page) {
        this.state.canvas.disableInput();
        this.state.canvas.viewport.innerHTML = "";
        // Create the container for the login page.
        const container = document.createElement("div");
        document.getElementById("debug-table")?.remove();
        container.id = "login-modal";
        container.innerHTML = page;
        this.state.canvas.viewport.appendChild(container);
    }

    /**
     * 
     */
    saveState() {
        console.log("Static router save state");
    }

    /**
     * 
     */
    shutdown() { 
        this.saveState(); 
    }

}