import { WebApplicationState } from "../../../WebApplicationState";
import page from "page";

/**
 * Shows the login page by setting the viewport to a login page.
 * @param {WebApplicationState} state the state of the application.
 */
export function showLoadingPage(state) {

    state.router.addPageToViewport(`
        <div class="login-page">
            <h2>Loading
                <span class="load-dot">.</span>
                <span class="load-dot">.</span>
                <span class="load-dot">.</span>
            </h2>
            <div class="loading-indicator">
        </div>
    `);
}