import { WebApplicationState } from "../../../WebApplicationState";

/**
 * Shows the login page by setting the viewport to a login page.
 * @param {WebApplicationState} state the state of the application.
 */
export function showTVLoginPage(state) 
{
    let redirectURL;
    if (window.location.hash.includes("redirect_")) 
    {
        state.externalModules.closeModule(state.externalModules.open_modules[0]);
        redirectURL = window.location.hash.split("_")[1];
    }

    state.router.addPageToViewport(`
        <div class="login-page">
        <h1>Sign in to Lakeside Cabin</h1>
        <div id="login-buttons">
            <button id="signin-button">Sign in</button>
            <button id="guest-button">Continue as guest</button>
            <button id="return">Home</button>
        </div>
        </div>
    `);

    // Sign in button, goes to the sign in page.
    document.getElementById("signin-button")?.addEventListener("click", () => 
    {
        state.auth.createLoginSession(redirectURL);
    });

    /**
     * Guest mode button, user can continue on without a JWT.
     * This will allow them to play only solo games. We will only store that they are a guest for 
     * their session. If they return, they will be redirected to attempt to sign them in again.
     */
    document.getElementById("guest-button")?.addEventListener("click", () => 
    {
        console.log("%cCreate guest session", "color: green");
        state.auth.createGuestSession();
    });

    document.getElementById("return")?.addEventListener("click", () => 
    {
        window.location.href = "/";
    });
}