
//@ts-check

import { WebApplicationSettings } from './WebApplicationSettings.js';

/**
 * 
 */
export class S3ContentBucket{
    /**
     * 
     * @param {import('./webApplication.js').WebApplication} application
     * @param {string} url 
     * @returns {string}
     */
    static applyURLPrefix(application, url){
        var url = url.replace('./',"https://sinclair-dl-project.nmp.nonprod-sinclairstoryline.com/"+application.getS3ContentBucketPath())
        return url;
    }
    /**
     * 
     * @param {import('./webApplication.js').WebApplication} application 
     * @returns 
     */
    static isEnabled(application){
        return application.getSetting(WebApplicationSettings.islocalhost_uses_s3_content_bucket_SettingName)
    }
}
