import { WebApplicationState } from "../../../../WebApplicationState";

/**
 * Shows the pending page, shown when the user is waiting for the phone to authenticate.
 * This is where the QR code is shown.
 * @param {String} connectionID the connection ID to show the QR code for.
 * @param {WebApplicationState} state the state of the application.
 */ 
export function showPendingPage(connectionID, state) 
{
    state.router.addPageToViewport(`
        <div class="login-page">
            <h1>Sign in to Lakeside Cabin</h1>
            <p>Scan the QR code below to get started.</p>
            <canvas id="qr-code"></canvas>
            <button id="cancel-button">Cancel</button> 
        </div>
    `);

    // Return to login page if cancel button is clicked
    document.getElementById("cancel-button")?.addEventListener("click", () => 
    {
        window.location.href = "/login";
    });
    state.auth.showQRCode(connectionID);
}