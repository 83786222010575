//@ts-check

/**
 *
 * @callback onError_CanvasElementFunction
 * @param {CanvasElement} elm
 * @param {string} error
 */

/**
 * 
 */
export class CanvasElement {
  /**
  *  @type {boolean}
  */
  is_invalidating_draw;
  /**
  *  @type {import('../resources/resourceInterface').ResourceInterface|undefined}
  */
  resource;
  /**
  *  @type {import('../resourceCanvasElements/ResourceCanvasElement').ResourceCanvasElementInterface|undefined}
  */
  resource_canvas_element;
  /**
  *  @type {number}
  */
  draw_order;
  /**
  *  @type {boolean}
  */
  isHidden;
  /**
   *  @type {number}
   */
  opacity;
  /**
  *  @type {import('../../interactive_canvas').InteractiveCanvas|undefined}
  */
  icanvas;
  /**
   *  @type {number|undefined}
   */
  autoInvalidateFrequencyPerSecond;
  /**
  *  @type {boolean}
  */
  isFirstTimeCanvasAdd = true;
  /**
  *  @type {Array}
  */
  errorLog = [];
  /**
 *  @type {onError_CanvasElementFunction}
 */
  onError;

  //warningLog;
  //onWarning;
  /**
  *  @type {import('../OnDrawingParams').onDrawingFunction}
  */
  onDrawing;
  /**
   * 
   * @param {import('../resources/resourceInterface').ResourceInterface|undefined} resource 
   * @param {import('../resourceCanvasElements/ResourceCanvasElement').ResourceCanvasElementInterface|undefined} resource_canvas_element 
   * @param {boolean} is_invalidating_draw 
   */
  constructor(resource = undefined, resource_canvas_element = undefined, is_invalidating_draw = true) {
    this.resource = resource;
    this.is_invalidating_draw = is_invalidating_draw;
    this.draw_order = 1;
    this.resource_canvas_element = resource_canvas_element;
  }
  /**
   * 
   * @returns {string|undefined}
   */
  toSourceURL() {
    return undefined;
  }
  /**
   * 
   * @param {string} error 
   * @param {boolean} notify 
   * @param {boolean} isLog 
   * @param {any} returnValue 
   * @returns {any}
   */
  addError(error, notify = true, isLog = true, returnValue = false) {
    this.errorLog.push(error);

    if (isLog) {
      console.error(error);
    }

    if (notify) {
      this.onError?.(this, error);
    }

    return returnValue;
  }

  /**
   * 
   */
  onCanvasResized() { }

  /**
   * 
   */
  draw() { }

  /**
   * 
   * @param {import('../../interactive_canvas').InteractiveCanvas} icanvas 
   */
  addedToInteractiveCanvas(icanvas) {
    this.icanvas = icanvas;
    this.icanvas.addAutoInvalidateFrequencyPerSecond(this, this.autoInvalidateFrequencyPerSecond);
    this.addingToInteractiveCanvas();
    this.isFirstTimeCanvasAdd = false;
  }
  /**
   * 
   */
  removedFromInteractiveCanvas() {
    this.icanvas?.removeAutoInvalidateFrequencyPerSecond(this);

    this.removeingFromInteractiveCanvas();
    this.icanvas = undefined;

  }
  /**
   * 
   */
  addingToInteractiveCanvas() {

  }
  /**
   * 
   */
  removeingFromInteractiveCanvas() {

  }
  /**
   * 
   */
  invalidate() {
    this.is_invalidating_draw = true;
  }
  /**
   * 
   */
  validate() {
    this.is_invalidating_draw = false;
  }
  /**
   * 
   * @param {boolean} value 
   */
  setHidden(value) {
    if (value == this.isHidden) {
      return;
    }
    if (value) {
      this.isHidden = true;
      // console.log("t");
    } else {
      this.isHidden = false;
      // console.log("f");
    }

    this.isHidden = value;
    this.invalidate();
  }
  /**
   * 
   * @returns {boolean|undefined}
   */
  isLoading() {
    return this.resource?.isLoading();
  }
  /**
   * 
   * @returns {Promise|undefined}
   */
  getFirstLoadingPromise() {
    return this.resource?.getLoadingPromise();
  }
  /**
   * 
   * @param {number} value 
   */
  setDrawOrder(value) {
    this.draw_order = value;
  }
  /**
   * 
   * @param {number|undefined} value 
   */
  setAutoInvalidateFrequencyPerSecond(value) {
    this.autoInvalidateFrequencyPerSecond = value;
    this.icanvas?.removeAutoInvalidateFrequencyPerSecond(this);
    this.icanvas?.addAutoInvalidateFrequencyPerSecond(this, this.autoInvalidateFrequencyPerSecond);
  }
}
