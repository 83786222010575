// @ts-check

import { MediaSourcePlayer } from './resourceCanvasElements/MediaSourcePlayer.js';
import * as htmlHelper from "../../htmlHelper.js";
import { RectangleGeometry } from '../../geometry/RectangleGeometry.js';
/**
 * @callback actionFunction
*/

/**
 * 
 */
export class MeidaSourcePlayerComponent {
    /** @type {MediaSourcePlayer} */
    player;
    /**
     * @type {import('../../WebApplicationState').WebApplicationState}
     */
    webApplicationState;


    /** @type {HTMLVideoElement | null} */
    buffer_video_element;

    /** @type {number} */
    buffer_video_index;

    /**
     * @type {actionFunction}
     */
    //onStartOnGesture;

    /**
     * 
     * @param {import('../../WebApplicationState').WebApplicationState} webApplicationState 
     */
    constructor(webApplicationState) {
        this.webApplicationState = webApplicationState;
    }

    onCanvasResized(){

        let image_rect = this.webApplicationState.simulation.geometry.get_absolute_rect_shape();

        this.player.setVideoSize(image_rect.w,image_rect.h)
    }
    /**
   * 
   */
    async initialize() {
        this.buffer_video_index = 0

        this.buffer_video_element = document.getElementById('video-');// this.webApplicationState.canvas.get_video_buffer(this.buffer_video_index);

        if ('MediaSource' in window) {
            htmlHelper.showElement(this.buffer_video_element);

            this.player = new MediaSourcePlayer(this.buffer_video_element);

            const playerReady = new Promise((resolve) => {
                this.player.addEventListener('ready', async () => {
                    resolve("PLAYER READY");
                });
            });

            this.player.initialize();
            await playerReady;
        } 
        else {
            // Fallback: Load video directly. Added by Braden as a temporary workaround.
            console.warn("MediaSource API not supported. Using fallback video source.");
            await this.buffer_video_element.play().catch((err) => {
                console.error("Fallback playback failed:", err);
            });
        }
    }


    /**
   * 
   */
    start() { }

    /**
     * 
     */
    startAOnGesture() {
        this.player.resumeOnGesture();
    }
    /**
     * 
     */
    onActivity() {
    }
    /**
    * 
    * @param {import('../interactive_canvas.js').InteractiveCanvas} icanvas 
    */
    drawFrame(icanvas) { }
    /**
     * 
     * @param {import('../interactive_canvas.js').InteractiveCanvas} icanvas 
     * @param {import('../../MouseEvent.js').InteractiveMouseEvent} e 
     */
    mousedown(icanvas, e) {
        this.startAOnGesture();
    }
    /**
     * 
     * @param {import('../interactive_canvas.js').InteractiveCanvas} icanvas 
     * @param {import('../../MouseEvent.js').InteractiveMouseEvent} e 
     */
    mouseup(icanvas, e) { }
    /**
     * 
     * @param {import('../interactive_canvas.js').InteractiveCanvas} icanvas 
     * @param {import('../../MouseEvent.js').InteractiveMouseEvent} e 
     */
    mousemove(icanvas, e) { }
    /**
    * 
    * @param {import('../interactive_canvas.js').InteractiveCanvas} icanvas 
    * @param {import('../../sceneGraph/InteractiveEvent.js').InteractiveEvent} ievent 
    */
    keydown(icanvas, ievent) {
        this.startAOnGesture();
    }
    /**
     * 
     * @param {*} e 
     */
    onTouchTap(e) {
        this.startAOnGesture();
    }
    /**
     * 
     * @param {*} e 
     */
    onTouchPan(e) { }
    /**
     * 
     * @param {*} e 
     */
    onTouchSwipe(e) {
        this.startAOnGesture();
    }
    /**
     * 
     * @param {*} e 
     */
    onTouchDistance(e) { }
    /**
     * 
     * @param {*} e 
     */
    onTouchRotate(e) { }
    /**
     * 
     * @param {*} e 
     */
    onTouchGesture(e) { }
    /**
 * 
 */
    saveState() {
    }
    /**
     * 
     */
    shutdown() {
        this.saveState();
    }
}