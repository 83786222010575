import { WebApplicationState } from "../../../WebApplicationState";

/**
 * Shows the login page by setting the viewport to a login page.
 * @param {WebApplicationState} state the state of the application.
 */
export function showLoadingPage(state) {
    state.router.addPageToViewport(`
        <div class="login-page">
            <h2>Loading...</h2>
            <div class="loading-indicator">
        </div>
    `);
}